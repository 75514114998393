<div class="view_count_wrapper p-5">
  <div class="inner_wrapper">
    <i class="icon"><img src="assets/images/tracking.svg" alt="tracking"/></i>
    <p>Click below to register your device id.</p>
    <button [disabled]="buttonState !== 0" class="btn button_primary" type="button" (click)="avoidViewCount()">
      <ng-container *ngIf="buttonState === 0">Stop tracking</ng-container>
      <ng-container *ngIf="buttonState === 1">
        <span class="spinner-border custom_spinner" role="status"></span>
        <span>
          Processing
        </span>
      </ng-container>
      <ng-container *ngIf="buttonState === 2">
        <i class="check_icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
          </svg>
      </i> Added</ng-container>
    </button>
  </div>
</div>
