import {Component, OnInit} from '@angular/core';
import {HomepageService} from "../services/homepage/homepage.service";
import * as amplitude from '@amplitude/analytics-browser'
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-tracking-preference',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './tracking-preference.component.html',
  styleUrl: './tracking-preference.component.scss'
})
export class TrackingPreferenceComponent {
  constructor(public homePageService: HomepageService, public router: Router) {
  }
  // Button State
  buttonState: number = 0;

  /**
   * To avoid view count
    */
  avoidViewCount() {
    this.buttonState = 1;
    const deviceId = amplitude.getDeviceId();
    if (deviceId) {
      this.homePageService.stopTrackingDeviceId(deviceId).subscribe({
        next: res => {
          this.buttonState = 2;
          console.log('Device Id added to non-tracking list')
        }, error: err => {
          console.log('Error while adding device Id to non-tracking list', err)
        }
      })
    }
  }
}
